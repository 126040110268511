import React, { memo, useEffect } from 'react';

import styled from 'styled-components';

import FullCard from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';

import ActivityLevelGraph from '../../../Components/activityLevelGraph';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActivityLevelProgressBar from '../../../Components/activityLevelProgressBar';
import { getDashboard } from '../../../Actions/dashboard.actions';

const ActivityLevelCard = ({ period, setCompareprevious }) => {
  const data = useSelector((store) => store.analytics?.activityLevel?.data?.current_activity);
  const previous = useSelector((store) => store.analytics?.activityLevel?.data?.previous_activity);
  const employee_data = useSelector((store) => store?.dashboard?.data);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getPrev = (filters) => {
    setCompareprevious(filters.compare_previous);
  };

  useEffect(() => {
    dispatch(getDashboard({ period: 'year' }));
  }, []);

  return (
    <FullCard>
      <CardHeader
        toolTipText={t("activitylevelTooltip")}
        name={t("activityLevelOverTime")}
        noFilters
        // noCompare={false}
        onSelect={getPrev}
      />
      <Wrapper>
      <ActivityLevelProgressBar active_employees={employee_data?.top_graph?.active_employees?.total} inactive_employees={employee_data?.top_graph?.inactive_employees?.total}/>
        {/* <ActivityLevelGraph data={data} isSinglePage previous={previous} period={period} /> */}
      </Wrapper>
    </FullCard>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export default memo(ActivityLevelCard);
