import React, { memo, useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getDashboard } from '../../Actions/dashboard.actions';

import MiniCard from '../../Components/miniCard';
import MediumCard from '../../Components/mediumCard';
import WordCloudChart from '../../Components/wordCloudChart';
import CompanyMoodGraph from '../../Components/companyMoodGraph';
import ActivityLevel from '../../Components/activityLevel';
import SurveysMiniTable from '../../Components/surveysMiniTable';
import CardHeader from '../../Components/cardHeader';
import HeaderName from '../../Components/headerName';
import Loading from '../../Components/loading';
import GlobalFilters from '../../Components/globalFilters';
import { getSurveyOverview } from '../../Actions/survey.actions';
import AccordianMediumCard from '../../Components/accordianMediumCard';
import copyToClipboard from '../../Util/clipboard';
import NewStyledSelect from '../../SharedStyles/newStyledSelect';
import { useTranslation } from 'react-i18next';
import ActivityLevelProgressBar from '../../Components/activityLevelProgressBar';
import MoreInsightsButton from '../../Components/moreInsightsButton';
import { useHistory } from 'react-router-dom';

const Dashboard = ({ isNeeded = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [period, setPeriod] = useState('year');
  const [department, setDepartment] = useState(undefined);
  const [location, setLocation] = useState(0);

  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const data = useSelector((store) => store.dashboard.data);
  const profileData = useSelector((store) => store.company?.profile);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const heartBeatData = useSelector((store) => store.survey?.overview);

  const goTo = () => history.push('/analytics/activity-level');

  useEffect(() => {
    setLoading(true);
    dispatch(getSurveyOverview({ period: 'year', positive: 'all' })).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboard({ period: 'year' }));
  }, [dispatch]);

  const getTextAnalyticsData = useCallback((data) => {
    if (!data) return [];
    return Object.keys(data).map((key) => ({ text: key, value: data[key].count }));
  }, []);

  const onPeriodChange = (p) => {
    setPeriod(p);
    setLoadingDashboard(true);
    dispatch(getDashboard({ period: p, department_id: department, country_id: location })).finally(() =>
      setLoadingDashboard(false),
    );
  };

  const onSelectChange = (id) => {
    const departmentId = id === 'all' ? undefined : id;
    setDepartment(departmentId);
    setLoadingDashboard(true);
    dispatch(getDashboard({ period, department_id: departmentId, country_id: location })).finally(() =>
      setLoadingDashboard(false),
    );
  };

  const onLocationChange = (id) => {
    const locationId = id === 'all' ? undefined : id;
    setLocation(locationId);
    setLoadingDashboard(true);
    dispatch(getDashboard({ period, department_id: department, country_id: locationId })).finally(() =>
      setLoadingDashboard(false),
    );
  };

  const handleChange = (value, setValue) => {
    copyToClipboard(value, setValue);
  };

  if (!data || loading) return <Loading />;

  const {
    top_graph: { active_employees, inactive_employees, messages },
    leading_issues,
    priority_issues,
    concerning_issues,
    text_analytics,
    company_mood,
    activity_over_time,
  } = data;
  return (
    <div>
      {loadingDashboard && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      <HeaderWrapper>
        <HeaderName>{t('workforceInsights')}</HeaderName>
        <StyledDiv>
          <NewStyledSelect onChange={handleChange} isNeeded />
          <GlobalFilters
            period={period}
            onPeriodChange={onPeriodChange}
            onSelectChange={onSelectChange}
            onLocationChange={onLocationChange}
          />
        </StyledDiv>
      </HeaderWrapper>

      <div className="copyFullImage">
        {profileData?.id !== 1381 ? (
          <Section>
            <MiniCard
              data={{
                name: 'Active Employees',
                data: {
                  amount: active_employees.total,
                  graphData: active_employees.previous_values,
                  comparison: active_employees.comparison,
                },
              }}
              width="31%"
              height={150}
              themeColor="green"
            />
            <MiniCard
              data={{
                name: 'Inactive Employees',
                data: {
                  amount: inactive_employees.total,
                  graphData: inactive_employees.previous_values,
                  comparison: inactive_employees.comparison,
                },
              }}
              width="31%"
              height={150}
              themeColor="blue"
            />
            <MiniCard
              data={{
                name: 'Total Messages Sent',
                data: {
                  amount: messages.total_sent,
                  graphData: messages.previous_values,
                  comparison: messages.comparison,
                },
              }}
              width="31%"
              height={150}
              themeColor="yellow"
            />
          </Section>
        ) : null}
        <Section>
          <MediumCard
            data={leading_issues}
            name={t('leadingIssues')}
            width="31%"
            height={490}
            url="/analytics/leading-issues"
            customWidth="400px"
            toolTipText={t('tooltip1')}
          />
          <MediumCard
            data={priority_issues}
            name={t('priorityIssues')}
            width="31%"
            height={490}
            url="/analytics/priority-issues"
            customWidth="400px"
            toolTipText={t('tooltip2')}
          />
          <AccordianMediumCard
            data={concerning_issues}
            name={t('clinicalInsights')}
            width="31%"
            height={490}
            url="/analytics/therapist-feedback"
            customWidth="400px"
            toolTipText={t('tooltip3')}
          />
        </Section>
        <Section>
          <DoubleCardLayout style={{ height: 490 }}>
            <CardHeader name={t('conversationalAnalytics')} noFilters customWidth="400px" toolTipText={t('tooltip4')} />
            <WordCloudChart data={getTextAnalyticsData(text_analytics)} />
          </DoubleCardLayout>
          <DoubleCardLayout style={{ height: 490 }}>
            <CardHeader name={t('companyMood')} noFilters customWidth="400px" toolTipText={t('tooltip5')} />
            <CompanyMoodGraph originalData={company_mood} period={period} />
          </DoubleCardLayout>
        </Section>
        <Section>
          <DoubleCardLayout style={{ height: 406 }}>
            <CardHeader name={t('activityLevelOverTime')} noFilters toolTipText={t('tooltip6')} />
            <Wrapper style={{ height: 'calc(100% - 120px)' }}>
              <ActivityLevelProgressBar
                active_employees={active_employees?.total}
                inactive_employees={inactive_employees?.total}
              />
            </Wrapper>
            <MoreInsightsButton style={{ marginTop: 30 }} onClick={goTo} />
          </DoubleCardLayout>
          <DoubleCardLayout style={{ height: 406 }}>
            <CardHeader name={t('heartbeat')} noFilters customWidth="400px" toolTipText={t('tooltip7')} />
            <SurveysMiniTable data={heartBeatData ?? []} />
          </DoubleCardLayout>
        </Section>
      </div>
    </div>
  );
};

const HoverWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Card = styled.div`
  background: white;
  min-width: 260px;

  border-radius: 5px;
  padding: 22px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 4px;

  margin-bottom: 35px;

  @media screen and (max-width: 840px) {
    width: 100% !important;
    margin-bottom: 15px;
  }
`;

const DoubleCardLayout = styled(Card)`
  width: 48.3%;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

export default memo(Dashboard);
