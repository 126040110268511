import React from 'react';
import styled from 'styled-components';
import HSBar from 'react-horizontal-stacked-bar-chart';

const ActivityLevelProgressBar = ({ active_employees, inactive_employees }) => {
  let total_employees = active_employees + inactive_employees;
  let adoption_rate = (active_employees / total_employees) * 100;
  return (
    <Wrapper>
      <FlexRow>
        <TextWrapper>Adoption Rate :</TextWrapper>
        <TextWrapper style={{ color: '#00c8a0', fontWeight: 600 }}>
          {isNaN(adoption_rate) ? '0' : adoption_rate.toFixed(2)}%
        </TextWrapper>
      </FlexRow>

      {isNaN(total_employees) ? (
        <TextWrapper>No Users Found</TextWrapper>
      ) : (
        <ProgressBarWrapper style={{ width: '100%' }}>
          <ProgressBar
            height={50}
            // showTextIn
            outlineColor="black"
            id="new_id"
            fontColor="black"
            data={[
              {
                name: '',
                value: active_employees || 0,
                description: active_employees,
                color: '#00c8a0',
              },
              {
                name: '',
                value: total_employees || 0,
                description: total_employees,
                color: '#bababa',
              },
            ]}
          />
        </ProgressBarWrapper>
      )}

      <ScaleWrapper>
        <FlexRow>
          <Box style={{ backgroundColor: '#00c8a0' }} />
          <TextWrapper>Registered Users: {active_employees || 0}</TextWrapper>
        </FlexRow>
        <FlexRow>
          <Box style={{ backgroundColor: '#bababa' }} />
          <TextWrapper>Onboarded Users : {isNaN(total_employees) ? 0 : total_employees}</TextWrapper>
        </FlexRow>
      </ScaleWrapper>
    </Wrapper>
  );
};

const ProgressBarWrapper = styled.div`
  width: 100%;
  & #new_id {
    border-radius: 10px;
  }
`;

const ProgressBar = styled(HSBar)`
  & #new_id {
    border-radius: 10px;
  }
`;

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;

const Box = styled.div`
  width: 20px;
  height: 20px;
`;

const TextWrapper = styled.div`
  color: grey;
  font-size: 15px;
`;

const ScaleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export default ActivityLevelProgressBar;
